import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Self,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { debounceTime, tap } from 'rxjs/operators';
import {
    DatePrecision,
    DISPLAY_DATE_FORMATS,
    READONLY_DATE_FORMATS,
    ValidationErrorMessages,
    popperDateTimeOptions,
    TooltipSettings,
} from '@bazis/form/models/form-element.types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateValidators } from '@bazis/form/validators/dateValidators';
import { Observable } from 'rxjs';
import { createPopper, Instance, Modifier, OptionsGeneric } from '@popperjs/core';
import { EntityFormControl } from '@bazis/form/models/form.types';
import { BazisFormService } from '@bazis/form/services/form.service';
import moment from 'moment';

@UntilDestroy()
@Component({
    selector: 'bazis-input-date',
    templateUrl: './input-date.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisInputDateComponent
    implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges, OnDestroy
{
    @ViewChild('calendar') calendar: ElementRef;

    @ViewChild('inputDate') inputDate: ElementRef;

    @Input() minDate: string = null;

    @Input() maxDate: string = null;

    @Input() inputFormat: string = 'YYYY-MM-DD';

    @Input() precision: DatePrecision = 'days';

    // знак вопроса может быть рядом с полем
    @Input() tooltipKey: string = null;

    // заголовок поля
    @Input() titleKey: string = null;

    // возможно, нужен будет какой-то коммент под полем
    @Input() noteKey: string = null;

    // параметры для tooltip
    @Input() tooltipParams: any = null;

    // параметры для title
    @Input() titleParams: any = null;

    // параметры для note
    @Input() noteParams: any = null;

    // настройки отображения и работы тултипа
    @Input() tooltipSettings: TooltipSettings = null;

    @Input() required$: Observable<boolean> = null;

    @Input() placeholderKey: string = '';

    @Input() placeholder: string = '';

    @Output() touched = new EventEmitter();

    // для кастомных текстов ошибок
    @Input() validationErrorMessages: ValidationErrorMessages = {};

    // наличие стиралки для поля
    @Input() hasEraser: boolean = true;

    // for datepicker (minYear)
    @Input() startYear: number = null;

    // for datepicker (list with All years)
    @Input() visibleAllYears: boolean = false;

    // маркеры для календаря
    @Input() marks$: Observable<{ [index: number]: string }>;

    // настройки popper
    @Input() popperOptions: Partial<OptionsGeneric<Partial<Modifier<any, any>>>> = null;

    @Input()
    disabledIntervals: string[][] = null;

    @Input() placeIcon: 'left' | 'right' = 'right';

    @Input() typeHeadCalendar: 'left' | 'center' = 'left';

    @Input() typeSelectMonth: 'list' | 'grid' = 'list';

    public onChange = (_: any) => {};

    public onTouched = () => {};

    isTouched = false;

    disabled = false;

    dateValue = new FormControl();

    calendarValue: Date[] = [null];

    displayFormat: string;

    readonlyFormat: string;

    showCalendar = false;

    mask = '';

    instanceDatePopper: Instance;

    isClickOnPopper = false;

    hasCleared = false;

    constructor(@Self() public ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }

    ngOnInit() {
        if (this.ngControl.control instanceof EntityFormControl) {
            BazisFormService.getPropertiesFromConfig(this, this.ngControl.control.$config);
        }

        this.displayFormat = DISPLAY_DATE_FORMATS[this.precision];
        this.readonlyFormat = READONLY_DATE_FORMATS[this.precision];
        this.initValidators();

        this.dateValue.valueChanges
            .pipe(
                debounceTime(0),
                tap(() => {
                    this.setControlValue();
                    this.setCalendarValue();
                }),
                untilDestroyed(this),
            )
            .subscribe();

        this.dateValue.statusChanges
            .pipe(
                debounceTime(0),
                tap((status) => {
                    if (status !== 'INVALID') {
                        let errors = this.ngControl.control.errors;
                        if (!errors) return;
                        delete errors.componentError;
                        errors = Object.keys(errors).length > 0 ? errors : null;
                        this.ngControl.control.setErrors(errors);
                    } else {
                        this.ngControl.control.setErrors({
                            ...this.ngControl.control.errors,
                            componentError: { ...this.dateValue.errors },
                        });
                    }
                }),
                untilDestroyed(this),
            )
            .subscribe();
        this.writeValue(this.ngControl.control.value);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const reference = this.inputDate.nativeElement;
            const popper = this.calendar.nativeElement;

            this.instanceDatePopper = createPopper(reference, popper, {
                ...popperDateTimeOptions,
                ...this.popperOptions,
            });
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log('[DEBUG] changes', changes, this.controlDate['$config']['name']);

        if (changes.minDate || changes.maxDate) {
            this.initValidators();
            this.dateValue.updateValueAndValidity();
        }
    }

    ngOnDestroy() {
        if (this.instanceDatePopper) this.instanceDatePopper.destroy();
    }

    initValidators() {
        const validators = [];
        if (this.minDate) {
            validators.push(
                DateValidators.minDateValidatorFn(this.displayFormat, this.precision, this.minDate),
            );
        }
        if (this.maxDate) {
            validators.push(
                DateValidators.maxDateValidatorFn(this.displayFormat, this.precision, this.maxDate),
            );
        }
        validators.push(DateValidators.invalidDateValidatorFn(this.displayFormat));
        this.dateValue.setValidators(validators);
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.dateValue.disable();
        } else {
            this.dateValue.enable();
        }
    }

    public markAsTouched() {
        if (!this.isTouched) {
            this.onTouched();
            this.isTouched = true;
            this.touched.emit(true);
        }
    }

    public writeValue(value): void {
        if (!this.ngControl.control) return;
        if (!value) {
            this.dateValue.setValue(null);
            this.setCalendarValue();
            return;
        }
        const controlValue = moment(value, this.inputFormat);
        if (!controlValue.isValid()) return;
        const displayValue = moment(controlValue, this.inputFormat).format(this.displayFormat);
        this.dateValue.setValue(displayValue);
        this.setCalendarValue();
    }

    setValueFromCalendar(value: string[]) {
        this.dateValue.setValue(value[0]);
        this.showCalendar = false;
        this.hasCleared = false;
    }

    getDateValueMoment() {
        return moment(this.dateValue.value, this.displayFormat, true);
    }

    setCalendarValue() {
        const date = this.getDateValueMoment();
        this.calendarValue = date.isValid() ? [date.toDate()] : [null];
    }

    setControlValue() {
        const date = this.getDateValueMoment();
        let dateValue = date.isValid() ? date.format(this.inputFormat) : null;
        this.onChange(this.dateValue.value ? dateValue : null);
    }

    openCalendar(e) {
        this.showCalendar = !this.showCalendar;
        if (this.instanceDatePopper) this.instanceDatePopper.update();
    }

    clickPopper() {
        this.isClickOnPopper = true;
    }

    clickPopperOutside() {
        this.showCalendar = false;
        this.isClickOnPopper = false;
    }

    onClear() {
        this.ngControl.control.setValue('');
        this.hasCleared = true;
    }
}
