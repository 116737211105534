import { FormControl } from '@angular/forms';
import { SearchSettings, SimpleData } from '@bazis/shared/models/srv.types';
import {
    IFilterConstructor,
    IFilterConstructor_dateRange,
    IFilterConstructor_multipable,
    IFilterConstructor_number,
    IFilterConstructor_numberRange,
    IFilterConstructor_options,
    IFilterConstructor_requestor,
    RequestGenerator,
} from '@bazis/list/classes/filter.types';
import { Observable } from 'rxjs';

export class Filter {
    entityType: string;

    code: string;

    titleKey: string;

    isMain: boolean = false;

    suffix: string;

    suffixes: string[];

    isSeparate: boolean = false; // не внутри общего фильтра filter, а отдельным параметром

    additionalRequestParams: any; // additional simple params for filter

    requestGenerator: (value: string | string[]) => RequestGenerator; // params generator for get request (complicated filters);

    order: number;

    isRangeDate: boolean = false;

    // 15 колоночная система сетки
    colSize: string = '3';

    control: FormControl = new FormControl(null);

    unitKey: string;

    emptyValue: any = undefined;

    appliedValue: any = undefined;

    constructor(
        public key: string,
        public type: FilterType = FilterType.Common,
        public options: IFilterConstructor,
    ) {
        this.emptyValue = this.getUnlinkedValue(options.emptyValue);
        this.value = this.getUnlinkedValue(this.emptyValue);
        this.appliedValue = this.getUnlinkedValue(this.value);

        this.isMain = options.isMain;
        this.order = options.order;
        this.unitKey = options.unit;
        this.colSize = options.colSize;
        this.entityType = options.entityType;
        this.titleKey = options.titleKey;
        this.code = options.code;
        this.suffix = options.suffix;
        this.isSeparate = options.isSeparate;
        this.additionalRequestParams = options.additionalRequestParams;
        this.suffixes = options.suffixes;
        this.requestGenerator = options.requestGenerator;
    }

    translateValueToLbl(value) {
        return value ? `${value}` : '';
    }

    protected transformValueForQuery(value) {
        return value;
    }

    get value(): any {
        return this.control.value;
    }

    set value(value: any) {
        this.control.setValue(value);
    }

    get appliedValueLbl(): string {
        return `${this.translateValueToLbl(this.appliedValue)}` || `${this.emptyValue}`;
    }

    get appliedItems(): string[] {
        if (this.appliedValue instanceof Array)
            return this.appliedValue.reduce((acc, item) => {
                if (item !== undefined) {
                    acc.push(item);
                }
                return acc;
            }, []);
        else if (this.appliedValue instanceof Object) {
            return [this.transformValueForQuery({ ...this.appliedValue })];
        } else return this.appliedValue ? [this.transformValueForQuery(this.appliedValue)] : [];
    }

    get isEmpty(): boolean {
        return this.isEqual(this.value, this.emptyValue);
    }

    get isAssigned(): boolean {
        return !this.isEmpty;
    }

    get isWaiting(): boolean {
        return !this.isEqual(this.appliedValue, this.value);
    }

    get isAffecting(): boolean {
        return !this.isEqual(this.appliedValue, this.emptyValue);
    }

    get isNew(): boolean {
        return (
            !this.isEqual(this.emptyValue, this.value) &&
            this.isEqual(this.emptyValue, this.appliedValue)
        );
    }

    get isCleared(): boolean {
        return (
            this.isEqual(this.emptyValue, this.value) &&
            !this.isEqual(this.emptyValue, this.appliedValue)
        );
    }

    get isChanged(): boolean {
        return (
            !this.isEqual(this.value, this.emptyValue) &&
            !this.isEqual(this.value, this.appliedValue) &&
            !this.isEqual(this.appliedValue, this.emptyValue)
        );
    }

    clear(): void {
        this.value = this.getUnlinkedValue(this.emptyValue);
    }

    markAsApplied(): void {
        this.appliedValue = this.getUnlinkedValue(this.value);
    }

    clearAndApply() {
        this.clear();
        this.markAsApplied();
    }

    restore(): void {
        this.value = this.getUnlinkedValue(this.appliedValue);
    }

    isValueApplied() {
        return this.isEqual(this.appliedValue, this.value);
    }

    public isEqual(valueA, valueB) {
        if (valueA instanceof Array && valueB instanceof Array)
            return (
                (valueA.length === 0 && valueB.length === 0) ||
                (valueB.every((item) => !!~valueA.indexOf(item)) &&
                    valueA.every((item) => !!~valueB.indexOf(item)))
            );
        else if (valueA instanceof Object && valueB instanceof Object)
            return JSON.stringify(valueA) === JSON.stringify(valueB);
        else return valueA === valueB;
    }

    protected getUnlinkedValue(value) {
        if (value instanceof Array) return [...value];
        else if (value instanceof Object) return { ...value };
        else return value;
    }
}

export class Filter_SimpleString extends Filter {
    forceLatin: boolean;

    formatter: Function;

    constructor(key: string, options: any) {
        super(key, FilterType.SimpleString, { emptyValue: '', ...options });
        this.forceLatin = options.forceLatin;
        this.formatter = options.formatter;
    }
}

export class Filter_Number extends Filter {
    minNumber: number;

    maxNumber: number;

    increment: number;

    precision: number;

    constructor(key: string, params?: IFilterConstructor & IFilterConstructor_number) {
        super(key, FilterType.Number, { emptyValue: null, ...params });
        if (params && params.hasOwnProperty('minNumber')) this.minNumber = params.minNumber;
        if (params && params.hasOwnProperty('maxNumber')) this.maxNumber = params.maxNumber;
        if (params && params.hasOwnProperty('increment')) this.increment = params.increment;
        if (params && params.hasOwnProperty('precision')) this.precision = params.precision;
    }
}

export class Filter_NumberRange extends Filter {
    minNumber: { [index: string]: number };

    maxNumber: { [index: string]: number };

    increment: { [index: string]: number };

    precision: { [index: string]: number };

    constructor(key: string, params?: IFilterConstructor & IFilterConstructor_numberRange) {
        super(key, FilterType.NumberRange, {
            ...params,
            emptyValue: params.suffixes.reduce(
                (value, current) => ((value[current] = null), value),
                {},
            ),
        });
        if (params && params.hasOwnProperty('minNumber')) this.minNumber = params.minNumber;
        if (params && params.hasOwnProperty('maxNumber')) this.maxNumber = params.maxNumber;
        if (params && params.hasOwnProperty('increment')) this.increment = params.increment;
        if (params && params.hasOwnProperty('precision')) this.precision = params.precision;
    }
}

export class Filter_Options extends Filter {
    multiple: boolean;

    searchSettings: SearchSettings;

    allOptionsList: SimpleData[];

    get appliedOptions() {
        if (this.allOptionsList) {
            const appliedValues = this.multiple
                ? this.appliedValue
                : this.appliedValue
                ? [this.appliedValue]
                : [];
            return appliedValues.map((appliedValue) =>
                this.allOptionsList.find((v) => appliedValue === v.id),
            );
        }
        return [];
    }

    constructor(
        key: string,
        o?: IFilterConstructor &
            IFilterConstructor_multipable &
            IFilterConstructor_requestor &
            IFilterConstructor_options,
    ) {
        super(key, FilterType.Options, { ...o, emptyValue: o.multiple ? [] : null });
        if (o && o.hasOwnProperty('multiple')) this.multiple = o.multiple;
        if (o && o.hasOwnProperty('allOptionsList')) this.allOptionsList = o.allOptionsList;
        if (o && o.hasOwnProperty('searchSettings')) this.searchSettings = o.searchSettings;
    }
}

export class Filter_Select extends Filter {
    multiple: boolean;

    searchSettings: SearchSettings;

    includeOnly: string[];

    allOptionsList: SimpleData[];

    includeOnly$: Observable<string[]>;

    get appliedOptions() {
        if (this.allOptionsList) {
            const appliedValues = this.multiple
                ? this.appliedValue
                : this.appliedValue
                ? [this.appliedValue]
                : [];
            return appliedValues.map((appliedValue) =>
                this.allOptionsList.find((v) => appliedValue === v.id),
            );
        }
        return [];
    }

    constructor(
        key: string,
        o?: IFilterConstructor &
            IFilterConstructor_multipable &
            IFilterConstructor_requestor &
            IFilterConstructor_options,
    ) {
        super(key, FilterType.Select, { ...o, emptyValue: o.multiple ? [] : null });
        if (o && o.hasOwnProperty('multiple')) this.multiple = o.multiple;
        if (o && o.hasOwnProperty('allOptionsList')) this.allOptionsList = o.allOptionsList;
        if (o && o.hasOwnProperty('includeOnly')) this.includeOnly = o.includeOnly;
        if (o && o.hasOwnProperty('searchSettings')) this.searchSettings = o.searchSettings;
        if (o && o.hasOwnProperty('fillOptionsListFromStatuses'))
            this.includeOnly$ = o.includeOnly$;
    }
}

export class Filter_Search extends Filter {
    multiple: boolean;

    searchSettings: SearchSettings;

    constructor(
        key: string,
        o?: IFilterConstructor & IFilterConstructor_multipable & IFilterConstructor_requestor,
    ) {
        super(key, FilterType.Search, { ...o, emptyValue: o.multiple ? [] : null });
        if (o && o.hasOwnProperty('multiple')) this.multiple = o.multiple;
        if (o && o.hasOwnProperty('searchSettings')) this.searchSettings = o.searchSettings;
    }
}

export class Filter_DateRange extends Filter {
    isRangeDate = true;

    constructor(key: string, params?: IFilterConstructor & IFilterConstructor_dateRange) {
        super(key, FilterType.DateRange, {
            ...params,
            emptyValue: params.suffixes.reduce(
                (value, current) => ((value[current] = null), value),
                {},
            ),
        });
    }
}

export class Filter_Date extends Filter {
    constructor(key: string, params: IFilterConstructor) {
        super(key, FilterType.Date, { ...params, emptyValue: null });
    }
}

export class Filter_Flag extends Filter {
    flags: { [flag: string]: string };

    constructor(key: string, params: IFilterConstructor) {
        super(key, FilterType.Flag, { ...params });
    }
}

export const FILTERS_TYPE_MAP = {
    simpleString: Filter_SimpleString,
    options: Filter_Options,
    select: Filter_Select,
    number: Filter_Number,
    numberRange: Filter_NumberRange,
    date: Filter_Date,
    search: Filter_Search,
    dateRange: Filter_DateRange,
    flag: Filter_Flag,
};

export enum FilterType {
    Common,
    SimpleString,
    Options,
    Select,
    Search,
    Date,
    Flag,
    Coded,
    DateRange,
    Number,
    NumberRange,
    DctItemSelection,
    Etc,
}
