import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import {
    BehaviorSubject,
    combineLatest,
    merge,
    mergeMap,
    Observable,
    of,
    share,
    shareReplay,
    Subject,
    switchMap,
    withLatestFrom,
} from 'rxjs';
import {
    catchError,
    combineLatestWith,
    debounceTime,
    delay,
    filter,
    map,
    skip,
    take,
    tap,
} from 'rxjs/operators';
import {
    ListFilterGroup,
    ListIndividualFilter,
    ListPagination,
    ListSettings,
} from '@bazis/list/models/list.types';
import { EntData, EntList, EntPriority } from '@bazis/shared/models/srv.types';
import { ActivatedRoute, Router } from '@angular/router';
import { Filter, FILTERS_TYPE_MAP, FilterType } from '@bazis/list/classes/filter';
import { BazisToastService } from '@bazis/shared/services/toast.service';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { TranslocoService } from '@jsverse/transloco';
import moment from 'moment';
import {
    API_DATETIME_FORMAT,
    BazisConfigurationService,
    SHARE_REPLAY_SETTINGS,
} from '@bazis/configuration.service';
import { buildFilterStr } from '@bazis/utils';
import { BazisAlertService } from '@bazis/shared/services/alert.service';

const defaultPaginationSettings: ListPagination = {
    offset: 0,
    limit: 36,
    count: 0,
};

@Injectable()
export class BazisListService {
    isLoading = new TemplateObservable(true);

    // триггер обновления списка
    protected _needUpdateList$ = new BehaviorSubject(false);

    protected _needGetLastItems$: BehaviorSubject<number> = new BehaviorSubject(null);

    updateCounts$ = new Subject();

    // настройки списка
    protected _listSettings: ListSettings = null;

    // значение фильтра обновлено из фильров обновлен
    protected _filterUpdated$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    // фильтр применен и обновлен
    protected _filterAppliedUpdated$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    protected _onInitFilterSettings$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    // флаг инициаализации и активации фильтров
    protected _isActivated = false;

    filters: { [key: string]: Filter } = null;

    activeKeys: string[] = [];

    entityIdToRemove$: Subject<string | string[]> = new Subject(); // full - remove entity using delete, listOnly - remove entity from list

    setPriority$: Subject<{ id: string; priority: EntPriority }> = new Subject();

    updatePagination$ = new BehaviorSubject(null);

    updateStaticFilter$: BehaviorSubject<any> = new BehaviorSubject({});

    updateSort$ = new BehaviorSubject(null);

    updateIndividualFilters$ = new BehaviorSubject(null);

    updateSearch$ = new BehaviorSubject('');

    selectedIndividualFilters$ = this.updateIndividualFilters$
        .asObservable()
        .pipe(shareReplay(SHARE_REPLAY_SETTINGS));

    requestIndividualFilters$ = this.selectedIndividualFilters$.pipe(
        filter(() => !!this._listSettings),
        map((individual) => {
            const requestIndividual = { ...individual };
            Object.keys(requestIndividual)
                .filter((key) => {
                    const ignoreSettings = this._listSettings.individualFilters.find(
                        (v) => v.id === key,
                    ).ignoreValuesInRequest;
                    return ignoreSettings && ignoreSettings.indexOf(requestIndividual[key]) > -1;
                })
                .forEach((key) => {
                    delete requestIndividual[key];
                });
            return requestIndividual;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    meta$ = this._onInitFilterSettings$.pipe(
        switchMap(() => this.getMeta()),
        map(() => {
            this.filters = {};

            const defineFilter = (key, settings) => {
                const type = FILTERS_TYPE_MAP[settings.filterType];
                if (settings.code && !this.filters[settings.code] && type) {
                    if (settings.fillOptionsListFromStatuses) {
                        settings.includeOnly$ = this.getStatuses$();
                    }
                    this.filters[settings.code] = type
                        ? new type(settings.code, {
                              ...settings,
                              ...settings.typeSettings,
                          })
                        : {};
                }
            };
            const generateFilters = (filters) => {
                Object.keys(filters).forEach((key) => {
                    defineFilter(key, filters[key]);
                    if (filters[key].filters) {
                        generateFilters(filters[key].filters);
                    }
                });
            };

            generateFilters(this._listSettings.filters);
            this.activate(this._listSettings);
            this.setFilterFromGetParams(this.activatedRoute.snapshot.queryParams);
        }),
        catchError((e) => {
            console.log(e);
            return of(null);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    remove$ = this.entityIdToRemove$.pipe(
        tap((id) => {
            id = Array.isArray(id) ? id : [id];
            this.list.set(this.list._.filter((v) => id.indexOf(v.id) === -1));
            this._needGetLastItems$.next(id.length);
            this.updateCounts$.next(true);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    updateFilters$ = combineLatest([this._filterAppliedUpdated$, this.meta$]).pipe(
        filter(() => this._isActivated),
        mergeMap(() => of(this.filters ? this.urlParams : null)),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    priority$ = this.setPriority$.pipe(
        withLatestFrom(
            this.selectedIndividualFilters$,
            this.updateFilters$,
            this.updateStaticFilter$,
        ),
        tap(([setPriorityData, individualFilters, updateFilters, staticFilters]) => {
            const priorityName = setPriorityData.priority.id;
            const priorityProperty = setPriorityData.priority.property;
            const priorityValue = setPriorityData.priority.value;
            const allFilters = {
                ...individualFilters,
                ...updateFilters,
                ...staticFilters.separate,
                ...staticFilters.filter,
            };
            const isVisibleFn = (filtersProperty, value) => {
                return filtersProperty === value || filtersProperty === `${value}`;
            };
            const isVisible = setPriorityData.priority.isVisibleFn || isVisibleFn;

            if (
                (setPriorityData.priority.defaultVisibility &&
                    allFilters[priorityProperty] === undefined) ||
                isVisible(allFilters[priorityProperty], priorityValue)
            ) {
                this.modifyEntity({
                    id: setPriorityData.id,
                    [priorityProperty]: priorityValue,
                });
            } else {
                this.entityIdToRemove$.next(setPriorityData.id);
            }
            this.updateCounts$.next(true);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    staticFilterCounts$ = merge(
        this.updateFilters$,
        this.requestIndividualFilters$,
        this.updateCounts$,
    ).pipe(
        filter(() => !!this._listSettings && this._isActivated),
        debounceTime(0),
        withLatestFrom(this.requestIndividualFilters$),
        switchMap(([initial, individual]) => {
            if (
                !this._listSettings.staticFilters ||
                this._listSettings.staticFilters.length === 0
            ) {
                return of([]);
            }
            const filters = this.requestParams;
            return this.entityService.getListsCount$(
                this._listSettings.staticFilters.map((staticFilter) => {
                    let searchFilters = {
                        ...this._listSettings.params,
                        ...filters?.filter,
                        ...individual,
                    };
                    let params = { ...filters?.separate };
                    if (staticFilter.isSeparate) {
                        params = { ...params, ...staticFilter.requestParams };
                    } else {
                        searchFilters = { ...searchFilters, ...staticFilter.requestParams };
                    }

                    const filterParams = filters?.filterParams
                        ? { ...filters.filterParams }
                        : { filterGroups: [] };
                    if (staticFilter?.filterGroups) {
                        filterParams.filterGroups = filterParams.filterGroups.concat(
                            staticFilter.filterGroups,
                        );
                    }

                    if (this._listSettings?.paramGroups) {
                        filterParams.filterGroups = filterParams.filterGroups.concat(
                            this._listSettings.paramGroups,
                        );
                    }

                    return {
                        entityType: this._listSettings.entityType,
                        filters: searchFilters,
                        filterParams,
                        params,
                    };
                }),
            );
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    staticFilters$ = this.staticFilterCounts$.pipe(
        map((counts) =>
            this._listSettings.staticFilters.map((filter, index) => {
                return {
                    ...filter,
                    count: counts[index],
                };
            }),
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    needNextList$ = combineLatest([
        this.updatePagination$,
        this.updateFilters$,
        this.updateStaticFilter$,
        this.updateSort$,
        this.selectedIndividualFilters$,
        this.requestIndividualFilters$,
        this.updateSearch$,
        this._needUpdateList$,
    ]).pipe(
        filter(() => this._isActivated),
        debounceTime(0),
        tap(
            ([
                pagination,
                filters,
                staticFilters,
                sort,
                individualFilters,
                requestIndividualFilters,
                search,
                needUpdateList,
            ]) => {
                this.navigate(pagination, filters, staticFilters, sort, individualFilters, search);
            },
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    list: TemplateObservable<EntData[]> = new TemplateObservable([]);

    initialList$ = this.needNextList$.pipe(
        filter(() => !!this._listSettings),
        tap(() => {
            this.isLoading.set(true);
            this.loadMore$.next(null);
        }),
        switchMap(
            ([
                pagination,
                filters,
                staticFilters,
                sort,
                individualFilters,
                requestIndividualFilters,
                search,
                needUpdateList,
            ]) =>
                this._fetchListPart$(
                    pagination,
                    staticFilters,
                    requestIndividualFilters,
                    sort,
                    search,
                ),
        ),
        withLatestFrom(this.updateSearch$),
        tap(([list, search]) => {
            this.list.set(list.list);
            this.isLoading.set(false);
            if (
                !list.list?.length &&
                this._listSettings.noElementsAlertOptions &&
                this.hasAnyFilter &&
                !search
            ) {
                const alert = this.alertService.create(this._listSettings.noElementsAlertOptions);
                alert.onDidDismiss().then((r) => {
                    if (r) {
                        this.router.navigateByUrl(r);
                    }
                });
            }
        }),
        catchError((e) => {
            this.isLoading.set(false);
            return of([]);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    loadMore$: BehaviorSubject<ListPagination> = new BehaviorSubject(null);

    listPart$ = this.loadMore$.pipe(
        withLatestFrom(
            this.updateStaticFilter$,
            this.requestIndividualFilters$,
            this.updateSort$,
            this.updateSearch$,
        ),
        switchMap(([pagination, staticFilters, requestIndividualFilters, sort, search]) =>
            pagination
                ? this._fetchListPart$(
                      { ...pagination, offset: pagination.offset + pagination.limit },
                      staticFilters,
                      requestIndividualFilters,
                      sort,
                      search,
                  )
                : of(null),
        ),
        tap((list) => {
            if (!list) return;
            this.list.set(this.list._.concat(list.list));
            this.loadMore$.next(null);
        }),
        catchError((e) => {
            this.loadMore$.next(null);
            return of([]);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    updatingLastItems$ = merge(this.loadMore$, this.isLoading.$).pipe(
        filter((v) => !!v),
        debounceTime(0),
        switchMap((v) =>
            this._needGetLastItems$.pipe(
                skip(1), // ignore previous value
                withLatestFrom(
                    this.updatePagination$,
                    this.updateStaticFilter$,
                    this.requestIndividualFilters$,
                    this.updateSort$,
                    this.updateSearch$,
                ),
                mergeMap(
                    ([count, pagination, staticFilters, requestIndividualFilters, sort, search]) =>
                        count
                            ? this._fetchListPart$(
                                  {
                                      limit: count,
                                      offset:
                                          pagination.offset + pagination.limit > pagination.count
                                              ? pagination.offset + pagination.limit - count
                                              : pagination.count - count > 0
                                              ? pagination.count - count
                                              : 0,
                                  },
                                  staticFilters,
                                  requestIndividualFilters,
                                  sort,
                                  search,
                              )
                            : of(null),
                ),
            ),
        ),
        tap((list) => {
            if (!list?.list || list.list.length === 0) return;
            const existing = [...this.list._];
            list.list.forEach((item) => {
                const index = existing.findIndex((v) => v.id === item.id);
                if (index === -1) {
                    existing.push(item);
                } else {
                    existing.splice(index, 1, item);
                }
            });
            this.list.set(existing);
        }),
    );

    entities$ = merge(this.list.$).pipe(
        map((result: EntData[]) =>
            result
                ? result.map((entity) => {
                      return {
                          ...entity.$snapshot,
                          $canAdd: entity.$canAdd,
                          $canDelete: entity.$canDelete,
                          $canChange: entity.$canChange,
                          $canView: entity.$canView,
                      };
                  })
                : [],
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    // to support previous version of list service
    list$ = this.initialList$.pipe(shareReplay(SHARE_REPLAY_SETTINGS));

    selectedStaticTab$ = this.updateStaticFilter$.pipe(
        map(() => this.calculateSelectesStaticFilter()),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    pagination$ = merge(this.listPart$, this.initialList$).pipe(
        filter((v) => !!v),
        map((list: EntList) => {
            const result = list.$meta?.pagination;
            if (
                this._listSettings.maxAllowedPage &&
                this._listSettings.maxAllowedPage * result.limit < result.count
            ) {
                result.count = this._listSettings.maxAllowedPage * result.limit;
            }
            return result;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    sortOptions$ = this.updateSort$.pipe(
        map(() => this._listSettings.sortSettings),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    selectedSort$ = this.updateSort$.asObservable();

    mapEntities$ = combineLatest([
        this.updateFilters$,
        this.updateStaticFilter$,
        this.requestIndividualFilters$,
        this.updateSearch$,
        this._needUpdateList$,
    ]).pipe(
        filter(() => !!this._listSettings),
        switchMap(([filters, staticFilters, individualFilters, search, needUpdateList]) =>
            this.getMapList(this.requestParams, staticFilters, individualFilters, search),
        ),
        map((result: EntList) => {
            if (result.list) {
                return result.list.map((entity) => entity.$snapshot);
            }
            return [];
        }),
        catchError((e) => {
            return of([]);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    filters$ = merge(this._filterUpdated$, this._filterAppliedUpdated$, this.updateFilters$).pipe(
        map(() => this.filters),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    filterProps$ = merge(this.meta$, this._filterUpdated$, this._filterAppliedUpdated$).pipe(
        map(() => {
            let config: any = {
                activeKeys: this.activeKeys,
                activeFilters: this.activeFilters,
                definedFilters: this.definedFilters,
                affectingFilters: this.affectingFilters,
                waitingFilters: this.waitingFilters,
                hasAnyFilter: this.hasAnyFilter,
                hasAnyWaitingFilter: this.hasAnyWaitingFilter,
                countNew: this.countNew,
                countCleared: this.countCleared,
                countChanged: this.countChanged,
                countAssigned: this.countAssigned,
                countAffecting: this.countAffecting,
            };
            return config;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        protected http: HttpClient,
        protected toast: BazisToastService,
        protected srv: BazisSrvService,
        protected router: Router,
        protected entityService: BazisEntityService,
        protected translocoService: TranslocoService,
        protected activatedRoute: ActivatedRoute,
        protected alertService: BazisAlertService,
        protected configurationService: BazisConfigurationService,
    ) {}

    getStatuses$(settings = this._listSettings) {
        return this.entityService
            .getEntityList$(settings.entityType, { limit: 0, meta: ['status_allowed'] })
            .pipe(map((v) => v.$meta.status_allowed));
    }

    getMeta(settings = this._listSettings) {
        let observable =
            settings.entityType.indexOf('classifier') > -1 ||
            settings.entityType.indexOf('organization') > -1 ||
            settings.skipSchema
                ? of(null)
                : of(
                      this.configurationService.schemas[
                          `${settings.entityType.split('.').join('__')}__schema_list`
                      ],
                  );
        return observable.pipe(
            mergeMap((response) => {
                const requests = [];
                if (!response) return of(requests);

                for (let filterField in settings.filters) {
                    if (response.attributes[filterField]) {
                        settings.filters[filterField].code =
                            settings.code && response.attributes[filterField].filterLabel
                                ? `${settings.code}__${response.attributes[filterField].filterLabel}`
                                : response.attributes[filterField].filterLabel ||
                                  settings.filters[filterField].code;

                        settings.filters[filterField] = {
                            ...settings.filters[filterField],
                            ...response.attributes[filterField],
                        };

                        if (
                            response.attributes[filterField].enumDict &&
                            (settings.filters[filterField].filterType === 'select' ||
                                settings.filters[filterField].filterType === 'search') &&
                            settings.filters[filterField].typeSettings &&
                            !settings.filters[filterField].typeSettings.allOptionsList
                        ) {
                            settings.filters[filterField].typeSettings.allOptionsList =
                                response.attributes[filterField].enum.map((v) => {
                                    return {
                                        id: v,
                                        name: response.attributes[filterField].enumDict[v],
                                    };
                                });
                        }
                    }
                    if (response.relationships[filterField]) {
                        settings.filters[filterField].code = settings.code
                            ? `${settings.code}__${response.relationships[filterField].filterLabel}`
                            : response.relationships[filterField].filterLabel;
                        settings.filters[filterField] = {
                            ...settings.filters[filterField],
                            ...response.relationships[filterField],
                        };
                        if (settings.filters[filterField].filters) {
                            requests.push(settings.filters[filterField]);
                        }
                    }
                }
                return of(requests);
            }),
            mergeMap((requests) => {
                return requests.length > 0
                    ? combineLatest(requests.map((requestParam) => this.getMeta(requestParam)))
                    : of(null);
            }),
            take(1),
            catchError((e) => {
                console.log(e);
                // tmp fix when schema not existed
                for (let filterField in settings.filters) {
                    settings.filters[filterField].code = settings.code
                        ? `${settings.code}__${filterField}`
                        : filterField;
                }
                return of(null);
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    setListSettings(listSettings: ListSettings) {
        this._isActivated = false;
        if (!listSettings.individualFilters) {
            listSettings.individualFilters = [];
        }
        this.list.set([]);
        this._listSettings = {
            pagination: { ...defaultPaginationSettings },
            ...listSettings,

            staticFilters: listSettings.staticFilters
                ? listSettings.staticFilters.map((staticFilter) => {
                      const filter = { ...staticFilter };
                      if (!staticFilter.requestParams) {
                          filter.entityType = 'statusy.status';
                          filter.requestParams = { status: staticFilter.id };
                      }
                      return filter;
                  })
                : [],
        };
        let defaultPage = this.activatedRoute.snapshot.queryParams.page
            ? parseInt(this.activatedRoute.snapshot.queryParams.page)
            : 1;
        if (this._listSettings.maxAllowedPage && defaultPage > this._listSettings.maxAllowedPage) {
            defaultPage = 1;
        }

        this.setStaticFilter(this.calculateSelectesStaticFilter(), false);
        this.setPageOffset(defaultPage);
        this.setSort(this.activatedRoute.snapshot.queryParams?.sort || listSettings.defaultSort);
        if (this.activatedRoute.snapshot.queryParams?.search) {
            this.setSearch(this.activatedRoute.snapshot.queryParams?.search);
        }
        // set individual filters
        const individualFilters = {};
        this._listSettings.individualFilters.forEach((filter: ListIndividualFilter) => {
            if (this.activatedRoute.snapshot.queryParams[filter.id]) {
                individualFilters[filter.id] = this.activatedRoute.snapshot.queryParams[filter.id];
            } else {
                individualFilters[filter.id] = filter.defaultValue;
            }
        });
        this.setIndividualFilters(individualFilters, false);

        this._onInitFilterSettings$.next(true);
    }

    getList(
        pagination,
        filters,
        staticFilters,
        individualFilters,
        sort = this._listSettings.defaultSort || null,
        search: string = '',
    ): Observable<EntList> {
        if (!this._listSettings || !this._listSettings.entityType) {
            console.error('[!] Не определен ключ сущности или конфигурация для загрузки списка');
            return;
        }

        let params = {
            ...this._listSettings.params,
            ...filters?.filter,
            ...staticFilters?.filter,
        };

        let separates = {
            ...filters?.separate,
            ...staticFilters?.separate,
        };

        const filterParams = filters?.filterParams
            ? { ...filters.filterParams }
            : { filterGroups: [] };

        if (staticFilters?.filterGroups) {
            filterParams.filterGroups = filterParams.filterGroups.concat(
                staticFilters.filterGroups,
            );
        }

        if (this._listSettings?.paramGroups) {
            filterParams.filterGroups = filterParams.filterGroups.concat(
                this._listSettings.paramGroups,
            );
        }

        return this.srv.fetchPortion$(
            this._listSettings.entityType,
            '',
            pagination.offset,
            pagination.limit,
            search,
            {
                filter: buildFilterStr({ ...params, ...individualFilters }, filterParams),
                sort,
                ...separates,
            },
            this._listSettings.meta === undefined
                ? ['for_change', 'for_delete', 'for_create']
                : this._listSettings.meta,
        );
    }

    getMapList(
        filters,
        staticFilters,
        individualFilters = null,
        search = null,
    ): Observable<EntList> {
        if (!this._listSettings || !this._listSettings.entityType) {
            console.error('[!] Не определен ключ сущности или конфигурация для загрузки списка');
            return;
        }

        let params = {
            ...this._listSettings.params,
            ...filters?.filter,
            ...staticFilters?.filter,
        };

        let separates = { ...filters?.separate, ...staticFilters?.separate };

        const filterParams = filters?.filterParams
            ? { ...filters.filterParams }
            : { filterGroups: [] };

        if (staticFilters?.filterGroups) {
            filterParams.filterGroups = filterParams.filterGroups.concat(
                staticFilters.filterGroups,
            );
        }

        if (this._listSettings?.paramGroups) {
            filterParams.filterGroups = filterParams.filterGroups.concat(
                this._listSettings.paramGroups,
            );
        }

        return this.srv.fetchPortion$(this._listSettings.entityType, '', 0, 1000, search, {
            filter: buildFilterStr({ ...params, ...individualFilters }, filterParams),
            ...separates,
        });
    }

    refresh() {
        this._needUpdateList$.next(true);
    }

    goToPage(pageNumber) {
        this.setPageOffset(pageNumber);
    }

    setPageOffset(pageNumber) {
        this.updatePagination$.next({
            ...this._listSettings.pagination,
            offset: (pageNumber - 1) * this._listSettings.pagination.limit,
        });
    }

    setStaticFilter(newFilter, resetOffset = true, resetFilters = true) {
        const staticFilter = this._listSettings.staticFilters.find(
            (filter) => filter.id === newFilter,
        );
        if (!staticFilter) {
            this.updateStaticFilter$.next(null);
            return;
        }
        if (resetFilters && this.filters) {
            Object.keys(this.filters).forEach((filter) => {
                if (this.filters[filter].entityType === 'statusy.status') {
                    this.filters[filter].value = [];
                    this.applyAll(false);
                }
            });
        }
        if (resetOffset && this.filters) this.setPageOffset(1);
        this.updateStaticFilter$.next(
            staticFilter.isSeparate
                ? { separate: staticFilter.requestParams }
                : { filter: staticFilter.requestParams, filterGroups: staticFilter.filterGroups },
        );
    }

    setSort(sort) {
        this.updateSort$.next(sort);
    }

    setIndividualFilters(individualFilters, resetOffset = true) {
        this.updateIndividualFilters$.next(individualFilters);
        if (resetOffset) this.setPageOffset(1);
    }

    setSearch(search) {
        this.updateSearch$.next(search);
    }

    navigate(
        pagination,
        filters = null,
        staticFilters = null,
        sort = null,
        individualFilters = null,
        search = '',
    ) {
        if (this._listSettings.reflectInUrl) {
            const replaceUrl = !!this.activatedRoute.snapshot.queryParams;
            const sortParam = sort && sort !== this._listSettings.defaultSort ? { sort } : null;
            const searchParam = search ? { search } : '';
            const settingsParams = {};
            if (this._listSettings.paramsToReflectInUrl) {
                this._listSettings.paramsToReflectInUrl.forEach((paramName) => {
                    if (this._listSettings.params[paramName] !== undefined) {
                        settingsParams[paramName] = this._listSettings.params[paramName];
                    }
                });
            }

            const queryParams = {
                ...filters,
                ...staticFilters?.filter,
                ...staticFilters?.separate,
                ...individualFilters,
                ...settingsParams,
                ...sortParam,
                ...searchParam,
            };

            const page = Math.floor(pagination.offset / pagination.limit) + 1;

            if (page > 1) {
                queryParams.page = page;
            }

            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams,
                replaceUrl,
            });
        }
    }

    setFilterFromGetParams(params) {
        const paramsFilterKeys = Object.keys(params).filter((v) => v !== 'page' && v !== 'limit');
        const keys = paramsFilterKeys.sort().join(',');
        const filterKeys = Object.keys(this.urlParams).sort().join(',');
        if (keys === filterKeys) {
            const diff = paramsFilterKeys.filter(
                (v) => this.urlParams[v].toString() !== params[v].toString(),
            );
            if (diff.length === 0) return;
        }
        Object.keys(this.filters).forEach((key) => {
            if (this.filters[key]?.suffixes?.length > 0) {
                this.filters[key].suffixes.forEach((suffix) => {
                    const paramName = `${this.filters[key].code}__${suffix}`;
                    this.filters[key].value = {
                        ...this.filters[key].value,
                        [suffix]: params[paramName] || null,
                    };
                });
            } else {
                let path = this.filters[key].code;
                path += this.filters[key].suffix ? `__${this.filters[key].suffix}` : '';
                if (params[path]) {
                    this.filters[key].value =
                        (this.filters[key] as any).multiple !== undefined &&
                        !Array.isArray(params[path])
                            ? [params[path]]
                            : params[path];
                } else {
                    this.filters[key].clear();
                }
            }
        });
        this.applyAll(false);
    }

    activate(o: ListSettings) {
        this.activeKeys = Object.keys(this.filters);

        for (let key in this.filters) {
            let filter = this.filters[key];

            if (filter) {
                filter.clear();
                filter.markAsApplied();
            }
        }
        this._isActivated = true;
    }

    deactivate() {
        this.filters = null;
        this.activeKeys = [];
        this.list.set([]);
        this._isActivated = false;
        this._filterAppliedUpdated$.next(true);
        this.updateSearch$.next('');
    }

    applyAll(resetPagination = true) {
        let haveStatusFilter = false;
        this.waitingFilters.forEach((filter) => {
            filter.markAsApplied();
            if (filter.entityType === 'statusy.status') {
                haveStatusFilter = true;
            }
        });
        if (resetPagination) {
            this.setPageOffset(1);
        }
        if (haveStatusFilter && this._listSettings.staticFilters.length > 0) {
            this.setStaticFilter(this._listSettings.staticFilters[0].id, resetPagination, false);
        }
        this.trigger();
    }

    applyOne(key: string) {
        this.filters[key].markAsApplied();
        this.trigger();
    }

    restoreAll() {
        this.waitingFilters.forEach((filter) => {
            filter.restore();
        });
        if (this.hasAnyFilter) this.clearAll();
    }

    clearAll() {
        this.affectingFilters.forEach((filter) => {
            filter.clear();
        });
    }

    clearAllAndApply() {
        this.affectingFilters.forEach((filter) => {
            filter.clear();
        });
        this.applyAll();
    }

    clearOne(key: string) {
        this.filters[key].clear();
    }

    clearOneAndApply(key: string) {
        this.filters[key].clear();
        this.applyOne(key);
    }

    updateFilter() {
        this._filterUpdated$.next(true);
    }

    calculateSelectesStaticFilter() {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        return this._listSettings.staticFilters.reduce((selected, filter) => {
            const requestParams = filter.requestParams;
            let isInRequest = Object.keys(requestParams).reduce((acc, param) => {
                if (queryParams[param] === requestParams[param]) return acc;
                if (!acc) return acc;
                if (!queryParams[param]) return false;
                let queryParam = queryParams[param];
                if (!Array.isArray(queryParam)) {
                    queryParam = queryParam ? [queryParam] : [];
                }
                if (!Array.isArray(requestParams[param])) {
                    requestParams[param] = [requestParams[param]];
                }
                return acc && queryParam.sort().join(',') === requestParams[param].sort().join(',');
            }, true);
            return isInRequest &&
                (!selected || filter.id !== this._listSettings.defaultStaticFilter)
                ? filter.id
                : selected;
        }, this._listSettings.defaultStaticFilter);
    }

    protected _fetchListPart$(pagination, staticFilters, requestIndividualFilters, sort, search) {
        return this.getList(
            pagination,
            this.requestParams,
            staticFilters,
            requestIndividualFilters,
            sort,
            search,
        ).pipe(
            map((list) => {
                if (list.list === undefined && Array.isArray(list)) {
                    return {
                        list: list.map((item, index) => ({
                            id: item.id || index + 1,
                            type: null,
                            $snapshot: { ...item, id: item.id || index + 1 },
                        })),
                    };
                }
                if (this._listSettings.processItemFn) {
                    list.list = list.list.map((item) => this._listSettings.processItemFn(item));
                }
                return list;
            }),
        );
    }

    protected trigger() {
        this._filterAppliedUpdated$.next(true);
    }

    get hasAnyFilter(): boolean {
        return (
            this.filters &&
            this.activeKeys.some((key) => this.filters[key] && this.filters[key].isAssigned)
        );
    }

    get hasAnyWaitingFilter(): boolean {
        return (
            this.filters &&
            this.activeKeys.some((key) => this.filters[key] && this.filters[key].isWaiting)
        );
    }

    get countNew(): number {
        return (
            this.filters &&
            this.activeKeys.reduce(
                (count, key) => (this.filters[key] && this.filters[key].isNew ? count + 1 : count),
                0,
            )
        );
    }

    get countCleared(): number {
        return (
            this.filters &&
            this.activeKeys.reduce(
                (count, key) =>
                    this.filters[key] && this.filters[key].isCleared ? count + 1 : count,
                0,
            )
        );
    }

    get countChanged(): number {
        return (
            this.filters &&
            this.activeKeys.reduce(
                (count, key) =>
                    this.filters[key] && this.filters[key].isChanged ? count + 1 : count,
                0,
            )
        );
    }

    get countAssigned(): number {
        return (
            this.filters &&
            this.activeKeys.reduce(
                (count, key) =>
                    this.filters[key] && this.filters[key].isAssigned ? count + 1 : count,
                0,
            )
        );
    }

    get countAffecting(): number {
        return (
            this.filters &&
            this.activeKeys.reduce(
                (count, key) =>
                    this.filters[key] && this.filters[key].isAffecting ? count + 1 : count,
                0,
            )
        );
    }

    get urlParams(): { [key: string]: string | string[] } {
        const urlParams = this.activeKeys.reduce((params, key) => {
            const filter = this.filters[key];
            if (filter.isAffecting) {
                if (filter.suffixes && filter.suffixes.length > 0) {
                    filter.appliedItems.forEach((appliedItem) => {
                        filter.suffixes.forEach((suffix) => {
                            if (appliedItem[suffix]) {
                                params[`${filter.code}__${suffix}`] = [appliedItem[suffix]];
                            }
                        });
                    });
                } else {
                    const path = filter.code + (filter.suffix ? `__${filter.suffix}` : '');
                    params[path] = filter.appliedItems;
                }
            }

            return params;
        }, {});
        return urlParams;
    }

    // need to modify date and date range filter values
    get requestParams(): {
        filter: { [key: string]: string | string[] };
        separate: { [key: string]: string | string[] };
        filterParams: { filterGroups: ListFilterGroup[] };
    } {
        const urlParams = this.activeKeys.reduce(
            (params, key) => {
                const filter = this.filters[key];
                const filterType = filter.isSeparate ? 'separate' : 'filter';
                if (filter.isAffecting) {
                    if (filter.suffixes && filter.suffixes.length > 0) {
                        filter.appliedItems.forEach((appliedItem) => {
                            filter.suffixes.forEach((suffix) => {
                                if (appliedItem[suffix]) {
                                    if (filter.type === FilterType.DateRange) {
                                        const fromUTC = moment(
                                            appliedItem[suffix].slice(0, -1),
                                        ).valueOf();
                                        params[filterType][`${filter.code}__${suffix}`] = [
                                            moment.utc(fromUTC).format(API_DATETIME_FORMAT),
                                        ];
                                    } else {
                                        params[filterType][`${filter.code}__${suffix}`] = [
                                            appliedItem[suffix],
                                        ];
                                    }
                                }
                            });
                        });
                    } else {
                        if (filter.type === FilterType.Date) {
                            // datepicker returns result now in utc format, for example 01-05-2022T00:00:00Z
                            // but user who is now in Moscow wants to get results for this date in Moscow,
                            // so we need to remove Z from the end, and convert 01-05-2022 as local in Moscow to utc
                            // + for api we need to send start and end of the day. Same idea is with date range
                            const fromUTC = moment(filter.appliedItems[0]).valueOf();
                            params[filterType][`${filter.code}`] = filter.appliedItems[0];
                        } else if (filter.requestGenerator) {
                            const result = filter.requestGenerator(filter.appliedItems);
                            params[filterType] = {
                                ...params[filterType],
                                ...result.filters,
                            };
                            if (result.filterParams?.filterGroups) {
                                params.filterParams.filterGroups =
                                    params.filterParams.filterGroups.concat(
                                        result.filterParams?.filterGroups,
                                    );
                            }
                        } else {
                            const path = filter.code + (filter.suffix ? `__${filter.suffix}` : '');
                            params[filterType][path] = filter.appliedItems;
                        }
                    }
                    if (filter.additionalRequestParams) {
                        params[filterType] = {
                            ...params[filterType],
                            ...filter.additionalRequestParams,
                        };
                    }
                }
                return params;
            },
            { filter: {}, separate: {}, filterParams: { filterGroups: [] } },
        );
        return urlParams;
    }

    get activeFilters() {
        return this.activeKeys.map((key) => this.filters[key]);
    }

    get definedFilters() {
        return this.activeKeys
            .filter((key) => this.filters[key].value)
            .map((key) => this.filters[key]);
    }

    get affectingFilters() {
        return this.activeKeys
            .filter((key) => this.filters[key].isAffecting)
            .map((key) => this.filters[key]);
    }

    get waitingFilters() {
        return this.activeKeys
            .filter((key) => this.filters[key].isWaiting)
            .map((key) => this.filters[key]);
    }

    modifyEntity(modifiedProperties) {
        if (!modifiedProperties.id) return;
        const existing = [...this.list._];
        const index = existing.findIndex((v) => v.id === modifiedProperties.id);
        if (index === -1) return;

        let newItem = modifiedProperties.$snapshot
            ? {
                  ...existing[index],
                  ...modifiedProperties,
              }
            : {
                  ...existing[index],
                  $snapshot: {
                      ...existing[index].$snapshot,
                      ...modifiedProperties,
                  },
              };
        existing.splice(index, 1, newItem);
        this.list.set(existing);
    }
}
